import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Image } from '../models/image';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Result } from '@core/models/result';
@Injectable({
  providedIn: 'root'
})
export class UploadImagesService {

  constructor(private httpClient: HttpClient) { }


  public upload(data: Image[]): Observable<Result> {

    const requestUrl = environment.urlApi + 'ImagenDocumento/guardarImagen/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });


    return this.httpClient.post<Result>(requestUrl, data,
      { headers: httpHeaders });

  }

  public getImages(idCompany: string, idBranch: string, idWarehouse: string, idDocument: string): Observable<Image[]> {

    const requestUrl = environment.urlApi + 'ImagenDocumento/GetImagenDocumentos/' + idCompany +
    '/' +  idBranch + '/' + idWarehouse + '/' +  idDocument;

    return  this.httpClient.get<Image[]>(requestUrl);

  }

}
