import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { retry } from "rxjs/operators";
import { DocumentoEntrada } from "@core/models/documentoEntrada.model";
import { ReporteRecepcionesDiarias } from "@core/models/reporte-recepciones-diarias";
import { Observable } from "rxjs";
import { Result } from "@core/models/result";
import { DocumentoEntradaDetalle } from "@core/models/documento-entrada-detalle";
import { Excel } from "@core/models/excel";
import { ResultDocument } from "@core/models/result-document";
import { ValorRule } from "@core/models/valor-rule";

@Injectable({
  providedIn: "root",
})
export class InboundDocumentServiceService {
  constructor(private httpClient: HttpClient) {}


//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}


  getDocumentsList(
    company: string,
    branch: string,
    start: any,
    end: any,
    idInbound: string,
    idSupplier: string,
    state: string
  ): Observable<DocumentoEntrada[]> {
    const requestUrl =
      environment.urlApi +
      "DocumentoEntrada/Entrada/" + this.buildURL(company,branch,idInbound,start,end , idSupplier,state);

    const response = this.httpClient
      .get<DocumentoEntrada[]>(requestUrl)
      .pipe(retry(3));
    console.log(response);
    return response;
  }

  getDocumentProgress(companyId: string, branchId: string, documentId: string) {
    const requestUrl =
      environment.urlApi +
      "Reporte/ReporteAvance/" + this.buildURL(companyId,branchId,documentId);

    return this.httpClient
      .get<ReporteRecepcionesDiarias[]>(requestUrl)
      .pipe(retry(3));
  }

  SaveInboundDocument(InboundDocument: DocumentoEntrada): Observable<Result> {
    const requestUrl =
      environment.urlApi + "DocumentoEntrada/documentoEntrada/";
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient
      .post<Result>(requestUrl, InboundDocument, { headers: httpHeaders })
      .pipe(retry(3));
  }

  async GetInboundDocument(
    idCompany: string,
    idBranch,
    idDocument: string
  ): Promise<DocumentoEntrada> {
    const requestUrl =
      environment.urlApi +
      "DocumentoEntrada/GetObtenerEntradaID/" + this.buildURL(idCompany,idBranch,idDocument);

    return await this.httpClient.get<DocumentoEntrada>(requestUrl).toPromise();
  }

  GetInboundDocumentDetail(
    idCompany: string,
    idBranch: string,
    idDocument: string
  ): Observable<DocumentoEntradaDetalle[]> {
    const requestUrl =
      environment.urlApi +
      "DocumentoEntrada/entradaDetalle/" + this.buildURL(idCompany,idBranch,idDocument);

    return this.httpClient
      .get<DocumentoEntradaDetalle[]>(requestUrl)
      .pipe(retry(3));
  }

  PostFile(file: Excel): Observable<Result> {
    const requestUrl =
      environment.urlApi + "DocumentoEntrada/PostGuardarDocumentoExcel/";
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const params = JSON.stringify(file);

    return this.httpClient.post<Result>(requestUrl, file, {
      headers: httpHeaders,
    });
  }

  PostFileNewTemplate(file: Excel): Observable<Result> {
    const requestUrl =
      environment.urlApi + "DocumentoEntrada/PostGuardarDocumentoExcelNuevo/";
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const params = JSON.stringify(file);

    return this.httpClient.post<Result>(requestUrl, file, {
      headers: httpHeaders,
    });
  }

  GetResultDocument(sesion: string): Observable<ResultDocument> {
    const requestUrl =
      environment.urlApi +
      "DocumentoResultado/GetDocumentoResultados/" +
      sesion;

    return this.httpClient.get<ResultDocument>(requestUrl);
  }

  GetResultDocumentNewTemplate(sesion: string): Observable<ResultDocument> {
    const requestUrl =
      environment.urlApi +
      "DocumentoResultado/GetDocumentoRecepcionNuevo/" +
      sesion;

    return this.httpClient.get<ResultDocument>(requestUrl);
  }

  GetApproveDocument(
    idCompany: string,
    idBranch: string,
    idDocument: string
  ): Observable<Result> {
    const requestUrl =
      environment.urlApi +
      "DocumentoEntrada/GetAprobarDocumento/" + this.buildURL(idCompany,idBranch, idDocument);

    return this.httpClient.get<Result>(requestUrl);
  }

  GetExcelVersionValorRule(valorRule : ValorRule): Observable<Result> {
    const requestUrl =
      environment.urlApi +
      "ReglasValores/ObtenerReglasValores/" 
      const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
      const params = JSON.stringify(valorRule);
      return this.httpClient.post<Result>(requestUrl, valorRule, {
        headers: httpHeaders,
      });
  }

  PostDeleteInboundDocument(inboundDocument: DocumentoEntrada) {
    const requestUrl =
      environment.urlApi + "DocumentoEntrada/PostBorrarRecepcion/";
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient
      .post<Result>(requestUrl, inboundDocument, { headers: httpHeaders })
      .pipe(retry(3));
  }

  PostDeleteInboundDocumentDetail(
    inboundDocumentDetail: DocumentoEntradaDetalle
  ) {
    const requestUrl =
      environment.urlApi + "DocumentoEntrada/PostBorrarRecepcionDetalle/";
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient
      .post<Result>(requestUrl, inboundDocumentDetail, { headers: httpHeaders })
      .pipe(retry(3));
  }
}
