import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  GetGuid(): string {
    const date = new Date();
    const guid =
      date.getFullYear() +
      "" +
      (date.getMonth() + 1) +
      "" +
      date.getDate() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes();

    return guid;
  }

  exportExcel(object: any, reportName: string) {

    if (object.length === 0) {
      alert("Debe de haber información para cargar.");
      return;
    }

    //  const replace = JSON.stringify(object, this.replacer);

    // const objectReplaced = JSON.parse(replace);

    /* table id is passed over here */

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(object);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // wb.Sheets['Sheet1']['B1'].z = 'Text';
    /* save to file */
    XLSX.writeFile(wb, reportName + ".xlsx");
  }

  replacer(key, value) {
    // Filtering out properties
    if (value === null) {
      return undefined;
    }
    return value;
  }

  /**
   * Load the states of reception
   */
  loadStatesReception() {
    const states = [
      { name: "Todos los estados", value: "undefined" },
      { name: "Pendiente", value: "BLOQ" },
      { name: "Aprobado", value: "DISP" },
      { name: "Terminado", value: "RECO" },
      { name: "Procesando", value: "REPA" },
    ];

    return states;
  }

  /**
   * Load the states of expedition
   */
  loadStatesExpedition() {
    const states = [
      { name: "Todos los estados", value: "undefined" },
      { name: "Pendiente", value: "BLOQ" },
      { name: "Aprobado", value: "DISP" },
      { name: "Terminado", value: "TERM" },
      { name: "Procesando", value: "PROC" },
      { name: "Despachado", value: "DESP" },
      { name: "Anulado", value: "ANUL" },
    ];

    return states;
  }

  /**
   * Make the correct format of date
   * @param date date to make the format
   */
  dateFormat(date: Date): string {
    date = new Date(date);
    let value =
      (date as Date).getFullYear() +
      "-" +
      ((date as Date).getMonth() + 1) +
      "-" +
      (date as Date).getDate();

    if (value === "1969-12-31") {
      value = "";
    }

    return value;
  }

  /**
   * RETURN THE NUNBER VALUE OF STRING PRESENTATION
   * @param value STRING WITH THE PRESENTATION
   * @param presentation UD OR CJ
   */
  separateItemPresentation(value: string, presentation: string): string {
    const list = value.split(" ");

    if (list.length > 5) {
      if (presentation === "CJ") {
        const CJ = list[0];
        return CJ;
      } else if (presentation === "Total") {
        const TOTAL = list[5];
        return TOTAL;
      } else {
        const UD = list[2];
        if (UD === "/") {
          return "";
        } else {
          return UD;
        }
      }
    } else {
      if (presentation === "CJ") {
        return "";
      } else if (presentation === "Total") {
        const TOTAL = list[3];
        return TOTAL;
      } else {
        const UD = list[0];

        return UD;
      }
    }
  }

  // Separate presentations of items to get the number of items
  // format amount presentation amount presentation
  // example 1.00 UD 1.00 CJ => 1.00
  separateItemPresentationNumber(value: string, presentation: string): string {
    const list = value.split(" ");
    var amount = "0";
    if(presentation == '/'){
      amount = list[list.indexOf(presentation) + 1];
    } else {
      amount = list[list.indexOf(presentation) - 1];
    }
    list.indexOf(presentation);
    
    if (amount !== undefined) {
      return amount;  
    } else {
      return "0";
    }
  }

  
}
