import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Consorcio } from '../models/consorcio.model';
import { Sucursal } from '@core/models/sucursal.model';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConsorcioService {

  constructor(private httpClient: HttpClient) { }


  getAll(query: string): Observable<Consorcio[]> {

    const requestUrl = environment.urlApi + 'Consorcio/consorcio_usuario/' + query;

    return this.httpClient.get<Consorcio[]>(requestUrl);
  }




  getBranches(sucursal: Sucursal): Observable<Sucursal[]> {

    const requestUrl = environment.urlApi + 'Sucursal/sucursalesUsuario/';

    return this.httpClient.post<Sucursal[]>(requestUrl, sucursal);
  }

}
