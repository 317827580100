import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Supplier } from '../models/supplier';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private httpClient: HttpClient) { }


  getSuppliers(idCompania: string): Observable<Supplier[]> {

    const requestUrl = environment.urlApi + 'Proveedor/proveedores/' + idCompania;


    return this.httpClient.get<Supplier[]>(requestUrl);
  }

}
