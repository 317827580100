// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  currentUser: "",
  // urlApi: 'http://192.168.1.126:9099/CCA_API/api/',

  //PRODUCCION SEGEX - SEGEX_EFLOW
  //urlApi: "http://192.168.10.15:8085/API/api/",

  // BLUE
  //  urlApi: 'http://192.168.1.126:9100/CCA_API/api/',
  // api fernanda
  // urlApi: 'http://25.1.44.79:8080/API/API/',

  // api CHINA
  // urlApi: 'http://192.168.0.5:8080/API/API/',

  // PRODUCCION SANDAL https://github.com/epracsupply/CCA_API.gitL
  urlApi: "https://customers.exinlogistics.com/CCA-API/api/",

  // LOCALHOST
  //urlApi: "http://localhost:50884/api/",

  //LAREDO
  //urlApi: "http://25.54.190.34:8082/CCA_API/api/",

  //AFE
  //urlApi: "https://10.3.1.60:83/api/",
  //urlApi: "https://wms.afe.global:83/api/",

    //urlApi: "https://consulta.sandalcr.com/API_TPL/api/",

  // urlApi: "http://192.168.1.205:5000/api/",

  //LogiFashion
  //urlApi: "http://10.19.40.4:82/api/",

  //urlApi: "http://localhost:50884/api/",


  production: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
