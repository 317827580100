import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';



import { Client } from '../models/client';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpClient: HttpClient) { }



  /**
   * Get all clients of company
   * @param idCompany idCompany
   */
  GetClientsCompany(idCompany: string): Observable<Client[]> {

    const requestUrl = environment.urlApi + 'Cliente/clientesCompania/' + idCompany;

    return this.httpClient.get<Client[]>(requestUrl);
  }



}
