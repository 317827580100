import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Seguridad } from '../models/seguridad.model';
import { Result } from '@core/models/result';

@Injectable({
  providedIn: 'root'
})
export class UsersService {


  constructor(private httpClient: HttpClient) { }


  getAllUsers(query: string) {

    const requestUrl = environment.urlApi + 'Usuario/usuarioFiltro/' + query;

    return this.httpClient.get<User[]>(requestUrl);
  }

  postUser(user: User): Observable<User> {

    const requestUrl = environment.urlApi + 'Usuario/usuario';

    return this.httpClient.post<User>(requestUrl, user);
  }

  postCreateUser(usuario: User): Observable<Result> {

    const requestUrl = environment.urlApi + 'Usuario/UsuarioMant';

    return this.httpClient.post<Result>(requestUrl, usuario);
  }


  getPermissions(label: string, email: string, permissionId: string): Observable<Seguridad[]> {

    const requestUrl = environment.urlApi + 'Usuario/seguridadUsuario/' + label + '/' + email + '/' + permissionId;

    // return this.httpClient.get<Seguridad[]>(requestUrl);
    return this.httpClient.get<Seguridad[]>(requestUrl);

  }


  updatePermission(email: any, permisos: Seguridad[]) {

    if (permisos.length > 0) {

      const requestUrl = environment.urlApi + 'Usuario/SeguridadPermiso/';

      return this.httpClient.post(requestUrl, permisos);

    } else {

      const requestUrl = environment.urlApi + 'Usuario/SeguridadPermisoLimpiar/';

      return this.httpClient.post(requestUrl, email);

    }
  }

  eliminarUsuario(usuario: User): Observable<Result> {

    const requestUrl = environment.urlApi + 'Usuario/EliminarUsuario';
    return this.httpClient.post<Result>(requestUrl, usuario);

  }


}
