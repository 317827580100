import { Component, HostListener } from '@angular/core';
import { HttpClient } from 'selenium-webdriver/http';
import { CompaniaLogo } from '@core/models/compania-logo';
import { WarehouseService } from '@core/services/warehouse.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cca';


}
