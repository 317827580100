import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { UsersService } from "./services/users.service";
import { ConsorcioService } from "./services/consorcio.service";
import { InboundDocumentServiceService } from "./services/inbound-document-service.service";
import { WarehouseService } from "./services/warehouse.service";
import { SupplierService } from "./services/supplier.service";
import { ItemService } from "./services/item.service";
import { OutBoundDocumentService } from "./services/out-bound-document.service";
import { UploadImagesService } from "@core/services/upload-images.service";
import { ClientService } from "@core/services/client.service";
import { UtilsService } from "@core/Utils/utils.service";
import { AuthenticationService } from "@core/services/authentication.service";
import { ReportService } from "@core/services/report.service";
import { SecurityService } from "@core/services/security.service";
import { IndicatorsService } from "@core/services/indicators.service";
import { ReportAgingService } from "./services/report-aging.service";
import { ReportControlService } from "./services/report-control.service";
import { ReportIngresoService } from "./services/report-ingreso.service";
import { AvancesProduccionService } from "./services/avances-produccion.service";
import { OutboundDocumentStepperService } from "./services/outbound-document-stepper.service";
import { SystemDetailTypeService } from "./services/system-detail-type.service";
import { RuleService } from "./services/rule.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    UsersService,
    ConsorcioService,
    InboundDocumentServiceService,
    WarehouseService,
    SupplierService,
    ItemService,
    OutBoundDocumentService,
    UploadImagesService,
    AuthenticationService,
    ClientService,
    UtilsService,
    ReportService,
    SecurityService,
    IndicatorsService,
    ReportAgingService,
    ReportControlService,
    ReportIngresoService,
    AvancesProduccionService,
    OutboundDocumentStepperService,
    SystemDetailTypeService,
    RuleService,
  ],
})
export class CoreModule {}
