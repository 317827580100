import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

// models
import { Item } from '@core/models/item';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Result } from '@core/models/result';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private httpClient: HttpClient) { }


//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}


  GetItems(idCompany: string, filter: string): Observable<Item[]> {

    const requestUrl = environment.urlApi + 'Articulo/articulosFiltro/' + this.buildURL(idCompany, filter);

    return this.httpClient.get<Item[]>(requestUrl);

  }

  GetItemById(item: Item): Observable<Result> {


    const requestUrl = environment.urlApi + 'Articulo/PostGetArticulo/';


    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(item);

    return this.httpClient.post<Result>(requestUrl, item,
      { headers: httpHeaders });

  }


}
