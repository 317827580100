import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

// Models
import { ReportInventory } from '../models/report-inventory';
import { ReportLot } from '@core/models/report-lot';
import { ReporteRecepcionesDiarias } from '@core/models/reporte-recepciones-diarias';
import { ReportDailyPosition } from '@core/models/report-daily-position';
import { ReportDailyCompararison } from '@core/models/report-daily-compararison';
import { ReportKardex } from '@core/models/report-kardex';
import { ReportPackingList } from '@core/models/report-packing-list';
import { ReportAntiquityInventory } from '@core/models/report-antiquity-inventory';
import { ReportExpirationInventory } from '@core/models/report-expiration-inventory';
import { ReportParameter } from '@core/models/report-parameter';
import { Result } from '@core/models/result';
import { Excel } from '@core/models/excel';
import { ReportExpeditionTrace } from '@core/models/report-expedition-trace';
import { ReportGeneralMovements } from '@core/models/report-general-movements';
import { ReportLocalizedInventory } from '@core/models/report-localized-inventory';
import { ReportInventoryCut } from '@core/models/report-inventory-cut';
import { ReportProcessedOrders } from '@core/models/report-processed-orders';
import { ReportAdvanceSupremeDispatch } from '@core/models/report-advance-supreme-dispatch';
import { ReportKardexPeriodo } from '@core/models/report-kardex-periodo';
import { ReportKardexPeriodoArticulo } from '@core/models/report-kardex-periodo-articulo';
import { ReportReservedInventory } from '@core/models/report-reserved-inventory';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClient: HttpClient) { }

 
//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}

  getReportInventory(idCompany: string, idBranch: string, idItem: string, itemName: string): Observable<Result> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|'
      + 'IDARTICULO:' + idItem + '|' + 'NOMBREARTICULO:' + itemName;


    const parameter: ReportParameter = {} as ReportParameter;
    parameter.parameters = parameters;

    const requestUrl = environment.urlApi + 'Reporte/PostReporteInventarios/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(parameter);

    return this.httpClient.post<Result>(requestUrl, parameter,
      { headers: httpHeaders });



  }


  getReportLot(idCompany: string, idBranch: string, idItem: string, itemName: string): Observable<Result> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|'
      + 'IDARTICULO:' + idItem + '|' + 'NOMBREARTICULO:' + itemName;


    const parameter: ReportParameter = {} as ReportParameter;
    parameter.parameters = parameters;

    const requestUrl = environment.urlApi + 'Reporte/PostReporteLote/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(parameter);

    return this.httpClient.post<Result>(requestUrl, parameter,
      { headers: httpHeaders });
  }

  getReportPankingList(idCompany: string, idBranch: string, idExpedition: string): Observable<ReportPackingList[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|'
      + 'IDEXPEDICION:' + idExpedition;

    const requestUrl = environment.urlApi + 'Reporte/GetReporteListaEmpaque/' +  parameters;


    return this.httpClient.get<ReportPackingList[]>(requestUrl);
  }

  getReportPankingDetailedList(idCompany: string, idBranch: string, dateStart: string, dateEnd: string): Observable<ReportPackingList[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|' + 'FECHAINICIO:' + dateStart + '|' + 'FECHAFIN:' + dateEnd;

    const requestUrl = environment.urlApi + 'Reporte/GetReporteListaEmpaqueDetalles/' + parameters;


    return this.httpClient.get<ReportPackingList[]>(requestUrl);
  }

  getReportItems(idCompany: string): Observable<ReportLot[]> {

    const parameters = 'IDCOMPANIA:' + idCompany;

    const requestUrl = environment.urlApi + 'Reporte/GetReporteArticulos/' + parameters;


    return this.httpClient.get<ReportLot[]>(requestUrl);
  }


  postReportDailyReceptions(reportParameter: ReportParameter): Observable<Result> {

    const requestUrl = environment.urlApi + 'Reporte/PostReporteAvance/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(reportParameter);

    return this.httpClient.post<Result>(requestUrl, reportParameter,
      { headers: httpHeaders });

  }

  getReportDailyPosition(companyId: string, startDate: string, endDate: string) {

    const parameters = 'IDCOMPANIA:' + companyId + '|'
      + 'Inicio:' + startDate + '|'
      + 'Final:' + endDate;


    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReportePosicionesDiarias/' + parameters;

    return this.httpClient.get<ReportDailyPosition[]>(requestUrl).pipe(
      retry(3)
    );

  }

  getReportDailyComparison(companyId: string, idBranch: string, idWareHouse: string, date: string) {

    const requestUrl = environment.urlApi + 'Reporte/GetReporteInventarioComparacionDiario/' +
    this.buildURL( companyId,idBranch , idWareHouse , date);

    return this.httpClient.get<ReportDailyCompararison[]>(requestUrl).pipe(
      retry(3)
    );

  }


  getReportKardex(companyId: string, idBranch: string, idWareHouse: string, startdate: string, endDate: string): Observable<ReportKardex[]> {

    const requestUrl = environment.urlApi + 'Reporte/GetReporteKerdexDiario/' + 
    this.buildURL(companyId,idBranch, idWareHouse,startdate,endDate);

    return this.httpClient.get<ReportKardex[]>(requestUrl).pipe(
      retry(3)
    );

  }

  getReportKardexPeriodo(companyId: string, idBranch: string, idWareHouse: string, startdate: string, endDate: string, idItem:string): Observable<ReportKardexPeriodo[]> {

    const requestUrl = environment.urlApi + 'reporte/GetReporteKardexPeriodo/' + 
    this.buildURL(companyId,idBranch ,idWareHouse, startdate, endDate, idItem);

    return this.httpClient.get<ReportKardexPeriodo[]>(requestUrl).pipe(retry(3));

  }


  getReportKardexPeriodoArticulo(companyId: string, idBranch: string, idWareHouse: string, startdate: string, endDate: string, idItem:string): Observable<ReportKardexPeriodoArticulo[]> {

    const requestUrl = environment.urlApi + 'reporte/GetReporteKardexPeriodoArticulo/' + 
    this.buildURL(companyId ,idBranch , idWareHouse , startdate ,endDate , idItem);

    return this.httpClient.get<ReportKardexPeriodoArticulo[]>(requestUrl).pipe(retry(3));

  }


  getReportReservedInventory(companyId: string, idBranch: string): Observable<ReportReservedInventory[]> {

    const requestUrl = environment.urlApi + 'reporte/GetInventarioReservado/' + this.buildURL(companyId,idBranch);

    return this.httpClient.get<ReportReservedInventory[]>(requestUrl).pipe(retry(3));

  }



  getReportAntiquity(idCompany: string, idBranch: string): Observable<ReportAntiquityInventory[]> {


    const requestUrl = environment.urlApi + 'Reporte/GetReporteInventarioAntHoja/' + this.buildURL(idCompany ,idBranch);


    return this.httpClient.get<ReportAntiquityInventory[]>(requestUrl);
  }


  getReportExpiration(idCompany: string, idBranch: string): Observable<ReportExpirationInventory[]> {

    const requestUrl = environment.urlApi + 'Reporte/GetReporteInventarioCaduHoja/' 
    +this.buildURL( idCompany, idBranch);

    return this.httpClient.get<ReportExpirationInventory[]>(requestUrl);
  }

  getReportExpeditionTrace(idCompany: string, idBranch: string): Observable<ReportExpeditionTrace[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReporteTrazabilidadPedido/' + parameters;

    return this.httpClient.get<ReportExpeditionTrace[]>(requestUrl);
  }

  getReportGeneralMovements(idCompany: string, idBranch: string, dateStart: string, dateEnd: string): Observable<ReportGeneralMovements[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|' + 'FECHAINICIO:' + dateStart + '|' + 'FECHAFIN:' + dateEnd;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReporteMovimientosGenerales/' + parameters;

    return this.httpClient.get<ReportGeneralMovements[]>(requestUrl);
  }

  getReportLocalizedInventory(idCompany: string, idBranch: string): Observable<ReportLocalizedInventory[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReporteInventarioLocalizado/' + parameters;

    return this.httpClient.get<ReportLocalizedInventory[]>(requestUrl);
  }

  getReportInventoryCut(idCompany: string, idBranch: string, date: string): Observable<ReportInventoryCut[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|' + 'FECHAINICIO:' + date;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReporteCorteInventario/' + parameters;

    return this.httpClient.get<ReportInventoryCut[]>(requestUrl);
  }

  getReportProcessedOrders(idCompany: string, idBranch: string, dateStart: string, dateEnd: string): Observable<ReportProcessedOrders[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|' + 'FECHAINICIO:' + dateStart + '|' + 'FECHAFIN:' + dateEnd;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReportePedidosProcesados/' + parameters;

    return this.httpClient.get<ReportProcessedOrders[]>(requestUrl);
  }

  getReportAdvanceSupremeDispatch(idCompany: string, idBranch: string, dateStart: string, dateEnd: string): Observable<ReportAdvanceSupremeDispatch[]> {

    const parameters = 'IDCOMPANIA:' + idCompany + '|'
      + 'IDSUCURSAL:' + idBranch + '|' + 'FECHAINICIO:' + dateStart + '|' + 'FECHAFIN:' + dateEnd;

    const requestUrl = environment.urlApi + 'Reporte/GetObtenerReporteAvanceDespSupreme/' + parameters;

    return this.httpClient.get<ReportAdvanceSupremeDispatch[]>(requestUrl);
  }






  postExportReport(data: any): Observable<Result> {

    const excel = {} as Excel;

    excel.data = data;

    const requestUrl = environment.urlApi + 'DocumentoEntrada/exportarExcel/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(excel);

    return this.httpClient.post<Result>(requestUrl, excel,
      { headers: httpHeaders });

  }

}
