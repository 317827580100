import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AvancesProduccionService {
  private reportAgingURL = `${environment.urlApi}productiondashboard/dashboard/`;
  // private reportAgingURL = `http://eflow-laredo/cca/api/productiondashboard/dashboard/0001`;
  private dataResponse: any;

  constructor(private httpClient: HttpClient) {}

  getData(companyId: string): Observable<any> {
    const response = this.httpClient
      .get(`${this.reportAgingURL}${companyId}`)
      .pipe(catchError(this.handleError));

    this.dataResponse = response;
    return response;
  }

  convertData(response: any) {
    let dataDevoluciones: any = [];
    let dataTraslados: any = [];
    let dataCapacidad: any = [];
    let dataPedidos: any = [];
    let dataRecibidos: any = [];

    const DEVOLUCIONES = response["DEVOLUCIONES"][0];
    const TRASLADOS = response["TRASLADOS"][0];
    const PEDIDOS = response["PEDIDOS"][0];
    const CAPACIDAD = response["CAPACIDAD"][0];
    const CAPACIDAD_LIMITE =
      response["CAPACIDAD_LIMITE"].length !== 0
        ? response["CAPACIDAD_LIMITE"][0].OPERACION_MAXIMA_UNIDADES
        : 0;
    const RECIBO = response["RECIBO"][0];

    DEVOLUCIONES
      ? (dataDevoluciones = ["", ...Object.values(DEVOLUCIONES)])
      : null;

    PEDIDOS ? (dataPedidos = ["", ...Object.values(PEDIDOS)]) : null;

    TRASLADOS ? (dataTraslados = ["", ...Object.values(TRASLADOS)]) : null;

    CAPACIDAD
      ? (dataCapacidad = [
          [
            "Utilidades",
            CAPACIDAD.DEVOLUCIONES,
            CAPACIDAD.PEDIDOS,
            CAPACIDAD.TRASLADOS,
            0,
            "",
          ],
          ["Limite", 0, 0, 0, CAPACIDAD_LIMITE, ""],
        ])
      : null;

    RECIBO ? (dataRecibidos = ["", ...Object.values(RECIBO)]) : null;

    return {
      dataDevoluciones,
      dataPedidos,
      dataTraslados,
      dataCapacidad,
      dataRecibidos,
    };
  }

  private handleError(error: Response) {
    console.log(error);
    const msg = `Error status code ${error.status} status ${error.statusText}`;
    return throwError(msg);
  }
}
