import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

// Models
import { Result } from '@core/models/result';
import { ReportAntiquityInventory } from '@core/models/report-antiquity-inventory';
import { ReportExpirationInventory } from '@core/models/report-expiration-inventory';
import { Indicator } from '@core/models/indicator';


@Injectable({
  providedIn: 'root'
})
export class IndicatorsService {

  constructor(private httpClient: HttpClient) { }

 
//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}


  getReportAntiquityResumen(idCompany: string, idBranch: string): Observable<ReportAntiquityInventory[]> {


    const requestUrl = environment.urlApi + 'Reporte/ObtenerReporteInventarioAntResumen/' + this.buildURL( idCompany, idBranch);


    return this.httpClient.get<ReportAntiquityInventory[]>(requestUrl);
  }


  getReportExpirationResumen(idCompany: string, idBranch: string): Observable<ReportExpirationInventory[]> {


    const requestUrl = environment.urlApi + 'Reporte/ObtenerReporteInventarioCadResumen/' + this.buildURL( idCompany ,idBranch);


    return this.httpClient.get<ReportExpirationInventory[]>(requestUrl);
  }

  getIndicatorReception(consultType: string, idCompany: string, idBranch: string): Observable<Indicator[]> {


    const requestUrl = environment.urlApi + 'indicador/indicadoresRecepcion/' + consultType + '/' + this.buildURL(idCompany, idBranch);


    return this.httpClient.get<Indicator[]>(requestUrl);
  }

  getIndicatorExpedition(consultType: string, idCompany: string, idBranch: string): Observable<Indicator[]> {


    const requestUrl = environment.urlApi + 'indicador/indicadoresExpedicion/' + consultType + '/' + this.buildURL(idCompany, idBranch);


    return this.httpClient.get<Indicator[]>(requestUrl);
  }


}
