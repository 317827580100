import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Permission } from "../models/permission";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(private httpClient: HttpClient) {}

  GetPermission(email: string): Observable<Permission[]> {
    const requestUrl = environment.urlApi + "Permiso/GetPermisos/" + email;
    return this.httpClient.get<Permission[]>(requestUrl);
  }
}
