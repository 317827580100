import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

// models
import { OutBoundDocument } from '@core/models/out-bound-document';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentoSalida } from '@core/models/documento-salida';
import { Excel } from '@core/models/excel';
import { Result } from '@core/models/result';
import { DocumentoSalidaDetalle } from '@core/models/documento-salida-detalle';
import { retry } from 'rxjs/operators';

//services
import { AuthenticationService } from '@core/services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class OutBoundDocumentService {

  constructor(private httpClient: HttpClient,private authService: AuthenticationService) { }




//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}


GetOutBoundDocuments(idCompany: string, idBranch: string,
  dateStart: any, dateEnd: any, idOutbound: string, idClient: string, state: string): Observable<OutBoundDocument[]> {

  let paramCorrection = this.buildURL(idCompany, idBranch, idOutbound, dateStart, dateEnd, idClient, state);
  
  const requestUrl = environment.urlApi + 'DocumentoSalida/GetDocumentoSalidas/'
  + paramCorrection;

  return this.httpClient.get<OutBoundDocument[]>(requestUrl);

}

async GetOutboundDocument(idCompany: string, idBranch: string, idDocument: string): Promise<DocumentoSalida> {
  const requestUrl = environment.urlApi + 'DocumentoSalida/GetDocumentoSalida/' + this.buildURL(idCompany, idBranch, idDocument);
  return await this.httpClient.get<DocumentoSalida>(requestUrl).toPromise();
}
 

  PostFile(file: Excel): Observable<Result> {
    let idUsuarioCreador = "";
    idUsuarioCreador = this.authService.getCurrentUser();
    const requestUrl = environment.urlApi + 'DocumentoSalida/PostGuardarDocumentoExcel/' + this.buildURL(idUsuarioCreador);
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = JSON.stringify(file);

    return this.httpClient.post<Result>(requestUrl, file,
      { headers: httpHeaders });
  }

  GetOutboundDocumentDetail(idCompany: string, idBranch: string, idDocument: string): Observable<DocumentoSalidaDetalle[]> {

    const requestUrl = environment.urlApi + 'DocumentoSalida/GetDocumentoSalidaDetalle/' + this.buildURL( idCompany, idBranch, idDocument);

    return this.httpClient.get<DocumentoSalidaDetalle[]>(requestUrl).pipe(
      retry(3)
    );

  }

  SaveOutboundDocument(outBound: DocumentoSalida): Observable<Result> {

    const requestUrl = environment.urlApi + 'DocumentoSalida/saveDocumentoSalida/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });


    return this.httpClient.post<Result>(requestUrl, outBound,
      { headers: httpHeaders }).pipe(
        retry(3)
      );

  }

  GetApproveDocument(idCompany: string, idBatch: string, idDocument: string): Observable<Result> {


    const requestUrl = environment.urlApi + 'DocumentoSalida/GetAprobarDocumento/' + this.buildURL(idCompany, idBatch , idDocument);

    return this.httpClient.get<Result>(requestUrl);

  }


  PostDeleteOutboundDocument(outboundDocument: DocumentoSalida) {
    const requestUrl = environment.urlApi + 'DocumentoSalida/PostBorrarExpedicion/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });


    return this.httpClient.post<Result>(requestUrl, outboundDocument,
      { headers: httpHeaders }).pipe(
        retry(3)
      );
  }

  PostDeleteOutboundDocumentDetail(outboundDocumentDetail: DocumentoSalidaDetalle) {
    const requestUrl = environment.urlApi + 'DocumentoSalida/PostBorrarExpedicionesDetalle/';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });


    return this.httpClient.post<Result>(requestUrl, outboundDocumentDetail,
      { headers: httpHeaders }).pipe(
        retry(3)
      );
  }


}
