import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Result } from '@core/models/result';
import { ValorRule } from "@core/models/valor-rule";


@Injectable({
  providedIn: 'root'
})
export class RuleService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get List of warehouse
   * @param groupType company
   */
  GetRule(valorRule : ValorRule) {

    const requestUrl =
      environment.urlApi +
      "ReglasValores/ObtenerReglasValores/" 
      const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
      const params = JSON.stringify(valorRule);
      return this.httpClient.post<Result>(requestUrl, valorRule, {
        headers: httpHeaders,
      });

  }


}
