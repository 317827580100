import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class OutboundDocumentStepperService {
  private API = `${environment.urlApi}DocumentoSalidaStepper/GetDocumentoSalidaStepper`;
  // private API = `	http://localhost:5001/api/DocumentoSalidaStepper/GetDocumentoSalidaStepper/10265`;

  constructor(private httpClient: HttpClient) {}

  getData(idExpedicion: string): Observable<any> {
    const response = this.httpClient
      .get(`${this.API}/${this.buildURL(idExpedicion)}`)
      .pipe(catchError(this.handleError));
    return response;
  }

  private handleError(error: Response) {
    console.log(error);
    const msg = `Error status code ${error.status} status ${error.statusText}`;
    return throwError(msg);
  }

  
//SE IMPLEMENTA LA FUNCION buildURL PARA EL TRATAMIENTO DE CARACTERES INVALIDOS Y ASI SE PUEDA GENERAR UNA URL VALIDA
buildURL(...params: string[]): string {
  const encodedParams = params.map((param) => {
    let encodedParam = encodeURIComponent(param);
    // Reemplazar manualmente los caracteres '~!*()_-.'
    encodedParam = encodedParam.replace('!', '%21');
    encodedParam = encodedParam.replace('~', '%7E');
    encodedParam = encodedParam.replace('*', '%2A');
    encodedParam = encodedParam.replace('(', '%28');
    encodedParam = encodedParam.replace(')', '%29');
    encodedParam = encodedParam.replace("'", '%27');
    encodedParam = encodedParam.replace('-', '%2D');
    encodedParam = encodedParam.replace('_', '%5F');
    encodedParam = encodedParam.replace('.', '%2E');
    return encodedParam;
  });
  return encodedParams.join('/');
}

}
