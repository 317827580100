import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ReportIngresoService {
  private reportAgingURL = `${environment.urlApi}Reporte/GetObtenerReporteIngreso/22A0000021175`;
  // private reportAgingURL = `http://localhost:3000/ingreso`;

  constructor(private httpClient: HttpClient) {}

  getData(): Observable<any> {
    const response = this.httpClient
      .get(this.reportAgingURL)
      .pipe(catchError(this.handleError));
    return response;
  }

  private handleError(error: Response) {
    console.log(error);
    const msg = `Error status code ${error.status} status ${error.statusText}`;
    return throwError(msg);
  }
}
