import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Usuario } from '@core/models/usuario.model';
import { Result } from '@core/models/result';
import { User } from '@core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUser = new BehaviorSubject<User>(null);


  currentUser$ = this.currentUser.asObservable();


  constructor(private httpClient: HttpClient) { }


  /**
   * set the email user register
   * @param user email
   */
  setCurrentUser(user: User) {
    // this.currentUser.next(user);
    sessionStorage.setItem('userId', user.email);
  }

/**
 * Get the email of user register
 */
  getCurrentUser() {
    // return environment.currentUser;}
    return sessionStorage.getItem('userId');
  }


  validateUser(usuario: Usuario): Observable<Result> {
    const requestUrl = environment.urlApi + 'Usuario/ValidarLogin';
    return this.httpClient.post<Result>(requestUrl, usuario);
  }

}
