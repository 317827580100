import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SystemDetailType } from '@core/models/system-detail-type';
import { Result } from '@core/models/result';



@Injectable({
  providedIn: 'root'
})
export class SystemDetailTypeService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get List of warehouse
   * @param groupType company
   */
  GetSystemDetailTypes(groupType: string) {

    const requestUrl = environment.urlApi + 'TipoSistemaDetalle/' + groupType;

    return this.httpClient.get<Result>(requestUrl);

  }


}
