import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Consorcio } from '../models/consorcio.model';
import { Sucursal } from '@core/models/sucursal.model';
import { retry } from 'rxjs/operators';
import { CompaniaLogo } from '@core/models/compania-logo';
import { Warehouse } from '@core/models/warehouse';



@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private httpClient: HttpClient) { }


  downloadLogo() {

    const requestUrl = environment.urlApi + 'ImagenDocumento/logo/';

    this.httpClient.get<CompaniaLogo>(requestUrl).subscribe(
      val => {

        localStorage.setItem('CompaniaLogo', JSON.stringify(val));

      }
    );

  }


  getLogo(): string {

    return 'data:image/png;base64,' + (JSON.parse(localStorage.getItem('CompaniaLogo')) as CompaniaLogo).logo;

  }

  /**
   * Get List of warehouse
   * @param idCompany company
   */
  GetFindWarehouseCompany(idCompany: string): Observable<Warehouse[]> {

    const requestUrl = environment.urlApi + 'Bodega/bodegaListaCompania/' + idCompany;

    return this.httpClient.get<Warehouse[]>(requestUrl);

  }


}
